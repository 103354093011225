import 'picturefill';
import 'lazysizes';
import 'lazysizes/plugins/print/ls.print';
import 'objectFitPolyfill/src/objectFitPolyfill.basic';

// On lazyload
window.addEventListener('lazyloaded', (event) => {
  // Object-fit polyfill
  if (event.target.parentNode && event.target.parentNode.classList.contains('image--cover')) {
    window.objectFitPolyfill(event.target);
  }

  // Add loading finished class
  if (event.target.parentNode && event.target.parentNode.classList.contains('loading')) {
    event.target.parentNode.classList.add('loading--finished');
  }
});
